.big-card {
  border-radius: 15px;
  padding-left: 3rem;
  padding-right: 3rem;
}

.contact-content {
  display: flex;
  flex-direction: column;
}
