.tag {
  margin-top: 12px;
  margin-bottom: 16px;
}

.roundify {
  border-radius: 8px!important;
}

.timeline-item-dateinner, .timeline-item-date {
  border-bottom-left-radius: 13px!important;
  border-top-left-radius: 13px!important;
}

.timeline {
  margin-top: 0;
}
