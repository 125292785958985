.about-me {
  margin-right: 2vw;
  margin-left: 2vw;
}

.title-left {
  font-size: x-large;
}

.libs {
  height: 30vh;
  overflow-y: scroll;
}