.portfolio-item-card {
  border-radius: 15px;
  overflow: hidden;
}

.message {
  width: 100%;
  text-align: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

.githubIcon {
  padding-right: 10px;
}